import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { usStates } from '../helpers.js'
import styles from '../scss/page.scss'
import axios from 'axios';
import qs from 'qs';
import Section from "../components/section/section";
import ModalCustom from "../components/modals/modal-custom";

class CounselorJobs extends React.Component {
  constructor(props) {
    super(props)
    this.modalCustom = React.createRef()
    const title = 'Counselor Jobs at Therapetic | Therapetic'
    this.seo = {
      title: title,
      description: '',
      seoTitle: title,
      children: [
        <link rel="canonical" href='https://therapetic.org/counselor-jobs' key='canonical-url'  />
      ]
    }
    this.state = {
      full_name: '',
      email: '',
      message: '',
      errors: {}
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.submit = this.submit.bind(this)
  }
  showModal(modal) {
    let modalName = `modal` + modal.substring(0, 1).toUpperCase() + modal.substring(1)
    if (modalName in this) {
      this[modalName].current.showModal()
    }
  }
  addNotify (msg) {
    if(msg === 200) {
      this.setState({
        msg: "We will contact you soon! Thanks."
      })
    } else {
      this.setState({
        msg: "An error occurred. Please contact our support."
      })
    }
  }
  resetForm() {
    this.setState({
      full_name: '',
      email: '',
      message: '',
      submitting: false,
      errors: {}
    });
  }
  /**
   * Handles inputs changes
   * @param  {Event} e
   * @return {void}
   */
  handleInputChange(e) {
    const target = e.currentTarget
    const value = target.value
    const stateProp = target.name
    this.setState({
      [stateProp]: value
    })
  }

  /**
   * Submits the form
   * @param  {Event} e
   * @return {void}
   */
  submit(e) {
    e.preventDefault()
    const form = e.currentTarget
    this.setState({
      errors: {},
      submitting: true
    }, () => {
      let errors = {}
      const requiredFields = [
        'full_name',
        'email',
        'message',
      ]
      requiredFields.forEach((field) => {
        if (!this.state[field]) {
          errors[field] = true
        }
      })
      if (Object.keys(errors).length) {
        this.setState({
          errors,
          submitting: false
        })
      } else {
        const params = {}
        for (const element of form.elements) {
          if (element.name) {
            params[element.name] = element.value
          }
        }
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(params),
          url: form.action,
        };
        axios(options).then(response => {
          e.preventDefault();
          this.showModal('Custom')
          this.addNotify(response.status)
          this.resetForm()
        })
          .catch(error => {
            e.preventDefault();
            this.showModal('Custom')
            this.addNotify(error)
            this.resetForm()
          });
      }
    })
  }

  render() {
    return (
      <Layout containerClassNames={`page page-counselor-jobs`}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Section className="counselor-jobs py-2 px-0">
          <div className="row">
            <div className='col-10 col-md-6 mt-5 mx-auto my-md-5'>
              <h1 className="text-lg-center text-md-left">Counselor Jobs</h1>
              <br/>
              <p>Are you a therapist, counselor, or a licensed mental health professional in general?</p>
              <br/>
              <p>If so, Therapetic wants you to join our team of highly qualified professionals on the world's largest online counseling platform focusing on animal-assisted therapy.</p>
              <br/>
              <h2>Benefits:</h2>
              <ul>
                <li>Your Time, Your Schedule</li>
                <ul><li>It’s all up to you. You decide your schedule and your availability!</li></ul>
                <li>Your Focus is on the Patient, and Only Them</li>
                <ul><li>No administrative work, no billing, no searching for clients. We provide you with all the resources you need so all your time is dedicated to helping the patient directly.</li></ul>
                <li>More Clients Everyday</li>
                <ul><li>We have thousands of clients all over US and Canada looking for a qualified therapist in their area so the stream of patients you receive never ends.</li></ul>
              </ul>
              <br/>
              <h2>Requirements:</h2>
              <ul>
                <li>You need to be licensed by a State Board to provide counseling (e.g., LCSW, LMFT, LPC, PsyD or similar credentials). We are not accepting any counselors other than mental health at this time.</li>
                <li>Experience in animal-assisted therapy techniques is highly encouraged.</li>
                <li>All counselor need to possess high-quality writing skills</li>
                <li>Due to Therapetic being a telehealth platform, it’s important to have reliable Internet connection.</li>
                <li>You must currently reside in the US.</li>
              </ul>
            </div>
          </div>
        </Section>
        <section className="bg-light signup-form">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <h2 className="text-primary mb-0 mt-4">Need more Information?</h2>
                <form onSubmit={this.submit} name="form-counselor-jobs" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="form-counselor-jobs" />
                  <input type="text" name="bot-field" className="d-none" /> {/* Input for preventing bots  */}
                  <input type="hidden" name="subject" value="Message sent from Counselor Jobs | Therapetic" />
                  <div className="row my-4">
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="full_name">Full Name</label>
                      <input type="text" className="form-control" id="full_name" name="full_name" value={ this.state.full_name } onChange={this.handleInputChange}/>
                      <small className={ `form-text text-danger ${this.state.errors.full_name ? '' : 'd-none'}` }>Please, enter your first name.</small>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="email">Email Address</label>
                      <input type="email" className="form-control" id="email" name="email" value={ this.state.email } onChange={this.handleInputChange} />
                      <small className={ `form-text text-danger ${this.state.errors.email ? '' : 'd-none'}` }>Please, enter your email.</small>
                    </div>
                    <div className="col-12">
                      <label htmlFor="message">Message</label>
                      <textarea className="form-control" id="message" name="message" value={ this.state.message } onChange={this.handleInputChange}>
                      </textarea>
                      <small className={ `form-text text-danger ${this.state.errors.message ? '' : 'd-none'}` }>Please, enter your message.</small>
                    </div>
                    <div className="col-12 my-4">
                      <button type="submit" className="btn-cta" disabled={this.state.submitting}>SEND MESSAGE</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <ModalCustom ref={this.modalCustom} message={this.state.msg}/>
      </Layout>
    )
  }
}

export default CounselorJobs

export const JobsPage = graphql`
  query JobsPage {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
  }
`